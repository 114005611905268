import React, { Component, Fragment } from "react";
import "./Events.sass";
import parseHTML from "../lib/parseHTML";
import PasswordModal from "./PasswordModal";

class EventItem extends Component {
  state = {
    show: false,
  };

  handleEventClick = (link) => {
    if (this.props.password && this.props.password.length > 0) {
      this.setState((prevState) => ({ ...prevState, show: !prevState.show }));
    } else {
      window.open(link);
    }
  };

  handleCheckPassword = (pwd) => {
    if (pwd == this.props.password) {
      window.open(this.props.link);
      this.setState((prevState) => ({ ...prevState, show: !prevState.show }));
      return true;
    }

    return false;
  };

  render() {
    const {
      title,
      description,
      thumbnail,
      link,
      dueDate,
      password,
      eventsStyles,
      protectedText,
    } = this.props;
    return (
      <Fragment>
        <li
          style={{ backgroundColor: eventsStyles.card_background_color }}
          onClick={() => this.handleEventClick(link)}
        >
          <img src={thumbnail} alt={title} />
          <div className="event-label">
            <div
              style={{ color: eventsStyles.card_title_color }}
              className="event-title"
            >
              {parseHTML(title)}
            </div>
            <div
              style={{ color: eventsStyles.card_description_color }}
              className="event-description"
            >
              {parseHTML(description)}
            </div>
          </div>
          <div className="event-duedate">{dueDate}</div>
        </li>
        {this.state.show && (
          <PasswordModal
            isShow={this.state.show}
            text={protectedText}
            onCheckPassword={this.handleCheckPassword}
          />
        )}
      </Fragment>
    );
  }
}

class Events extends Component {
  render() {
    const { events, eventsStyles, protected_text } = this.props;
    return (
      <ul className="events">
        {events?.map((i, k) => (
          <EventItem
            eventsStyles={eventsStyles}
            protectedText={protected_text}
            key={k}
            {...i}
          />
        ))}
      </ul>
    );
  }
}

export default Events;
