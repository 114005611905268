import React, { useEffect } from "react";
import "./Search.sass";

import FilterICon from "../assets/filter.png";

function Toolbar({ value, placeholder, onChange, onSidebarOpen, events }) {
  useEffect(() => {
    document
      .querySelector("input[type=text]")
      .style.setProperty("--c", `${events.search_input_color}`);
  }, [events.search_input_color]);

  return (
    <div className="toolbar">
      <div className="toolbar-search">
        <input
          style={{
            color: events.search_input_color,
            backgroundColor: events.search_input_background,
          }}
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
      <div className="toolbar-buttons">
        <button onClick={() => onChange("")}>✕</button>
        <button onClick={() => onSidebarOpen()}>
          <img src={FilterICon} style={{ width: "16px", height: "16px" }} />
        </button>
      </div>
    </div>
  );
}

export default Toolbar;
